import { fetch } from '..';
import { QueryMethod } from '../utils';
import { UploadedFileInfo } from './dto';
import ReactPDF from '@react-pdf/renderer';
import { toast } from "sonner";
import axios from 'axios';

export function arrayBufferToBase64(arrayBuffer: ArrayBuffer): string {
  const uint8Array = new Uint8Array(arrayBuffer);
  const chunkSize = 0x8000; // 32KB chunks
  const chunks = [];

  for (let i = 0; i < uint8Array.length; i += chunkSize) {
    const chunk = uint8Array.slice(i, i + chunkSize);
    chunks.push(String.fromCharCode.apply(null, Array.from(chunk)));
  }

  return btoa(chunks.join(''));
}

export async function downloadAgreementsLink(key: string) {
  const url = await fetch<string>(
    `/gogeta-api/v1/files/download-pdf-files`,
    QueryMethod.POST,
      { key }
  );

  const downloadLink = document.createElement('a');
  downloadLink.href = url;
  downloadLink.target = "_blank";
  document.body.appendChild(downloadLink);
  downloadLink.click();
  document.body.removeChild(downloadLink);
}

export async function downloadFlyersLink(key: string, isCustomFlyerLink?: boolean) {
  const url = isCustomFlyerLink ? key : await fetch<string>(
    `/gogeta-api/v1/files/download-flyers-files`,
    QueryMethod.POST,
      { key }
  );

  const downloadLink = document.createElement('a');
  downloadLink.href = url;
  downloadLink.target = "_blank";
  document.body.appendChild(downloadLink);
  downloadLink.click();
  document.body.removeChild(downloadLink);
}

export async function forceDownloadFlyersLink(key: string) {
  const fileUrl = await fetch<string>(
    `/gogeta-api/v1/files/download-flyers-files`,
    QueryMethod.POST,
    { key }
  );
  let url = '';
  try {
    const fileResponse = await axios.get(fileUrl, {responseType: 'blob'});
    const blob = new Blob([fileResponse.data], {type: 'application/pdf'});
    url = URL.createObjectURL(blob);
    setTimeout(() => URL.revokeObjectURL(url), 100);
  } catch (e) {
    toast.error('Could not load file');
  }

  const downloadLink = document.createElement('a');
  downloadLink.href = url;
  downloadLink.download = key;
  document.body.appendChild(downloadLink);
  downloadLink.click();
  document.body.removeChild(downloadLink);
}


export async function getSignedAgreementLink(key: string) {
  return fetch<string>(
      `/gogeta-api/v1/files/download-pdf-files`,
      QueryMethod.POST,
      { key }
  );
}

export async function getFlyersLink(key: string) {
  return fetch<string>(
      `/gogeta-api/v1/files/download-flyers-files`,
      QueryMethod.POST,
      { key }
  );
}


export async function uploadPDFFiles(fileData: ReactPDF.UsePDFInstance, fileName: string) {
  const buffer =  await fileData.blob?.arrayBuffer();

  if (!buffer) {
    toast.success('Please refresh the page and try again.');
    throw Error('There is no pdf file');
  }

  const base64String = arrayBufferToBase64(buffer);

  const data = {
    fileData: base64String,
    fileDefinitions: {
      originalFileName: fileName,
      fileSize: fileData.blob?.size,
    }
  };

  return await fetch<UploadedFileInfo>(
    `/gogeta-api/v1/files/upload-pdf-files`,
    QueryMethod.POST,
    data,
  );
}

export async function uploadPDFFilesUsingBlob(fileData: Blob, fileName: string) {
  const buffer =  await fileData.arrayBuffer();

  if (!buffer) {
    toast.success('Please refresh the page and try again.');
    throw Error('There is no pdf file');
  }

  const base64String = arrayBufferToBase64(buffer);

  const data = {
    fileData: base64String,
    fileDefinitions: {
      originalFileName: fileName,
    }
  };

  return await fetch<UploadedFileInfo>(
      `/gogeta-api/v1/files/upload-pdf-files`,
      QueryMethod.POST,
      data,
  );
}
